import React from "react"
import PropTypes from 'prop-types';
//import { Link } from "gatsby"
//import ArticleCard from '../pages/articles/articleCard'
import { withStyles} from '@material-ui/core/styles';
import Layout from "../components/layout";
import { Grid, Typography, } from "@material-ui/core";
import 'moment/locale/es-us'
//import { ContactsOutlined } from '@material-ui/icons';

const styles = theme =>({
    topTitle:{
        marginTop: theme.spacing(3),
        fontFamily: 'Arial Black'
    },
    PrincipalImg:{
        width:"100%",
        height:"45vh",
        borderRadius: "8px",
        objectFit: "contain"
      }
}); 

class ArticleTemplate extends React.Component{
    render(){
    
    const { classes, pageContext } = this.props;
    const title = pageContext.data.title
    const altIMG = (pageContext.data.img!== '')?pageContext.data.title:''
    const image = pageContext.data.img
    const bodyText = pageContext.data.body//"Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica, as well as most oceanic island chains. The group is paraphyletic as it excludes the snakes and Amphisbaenia; some lizards are more closely related to these two excluded groups than they are to other lizards. Lizards range in size from chameleons and geckos a few centimeters long to the 3 meter long Komodo dragon. Most lizards are quadrupedal, running with a strong side-to-side motion. Others are legless, and have long snake-like bodies. Some such as the forest-dwelling Draco lizards are able to glide. They are often territorial, the males fighting off other males and signalling, often with brightly colours, to attract mates and to intimidate rivals. Lizards are mainly carnivorous, often being sit-and-wait predators; many smaller species eat insects, while the Komodo eats mammals as big as water buffalo. Lizards make use of a variety of antipredator adaptations, including venom, camouflage, reflex bleeding, and the ability to sacrifice and regrow their tails."    

    //console.log(pageContext)
    
    return (
        <Layout>
            <Grid
             container
             direction="column"
             alignItems="center"
             justify="center"
             className={classes.topTitle}
            >
             <Typography gutterBottom variant="h2" component="h1" >
              {title}
            </Typography>
            {image!==''?
           <img src={image}  className={classes.PrincipalImg} alt={altIMG}/>
          : ''}
          </Grid>
         <Grid> 
             <Typography variant="body1" component="p"
              dangerouslySetInnerHTML={{ __html: bodyText}}>
             </Typography>
         </Grid>
        </Layout>
    )
    }
}

ArticleTemplate.propTypes = {
    classes: PropTypes.object.isRequired,
  };


export default withStyles(styles)(ArticleTemplate)
